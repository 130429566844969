export const BitmeshIcon: React.FC<{ size?: number }> = ({ size = 40 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="20" cy="20" rx="20" ry="20" fill="white" />
      <path
        d="M32.9482 15.5619C33.0912 15.392 33.2476 15.2712 33.4174 15.1996C33.5962 15.1281 33.7928 15.0923 34.0073 15.0923C34.6687 15.0923 34.9994 15.3964 34.9994 16.0047V24.2166C34.9994 24.476 34.9234 24.646 34.7715 24.7265C34.6195 24.807 34.3961 24.8472 34.1011 24.8472C33.8062 24.8472 33.5828 24.807 33.4308 24.7265C33.2789 24.646 33.2029 24.476 33.2029 24.2166V17.8564L27.8539 24.3374C27.7019 24.5163 27.5455 24.646 27.3847 24.7265C27.2238 24.807 27.0272 24.8472 26.7948 24.8472C26.5356 24.8472 26.3166 24.807 26.1379 24.7265C25.9681 24.646 25.8117 24.5163 25.6687 24.3374L20.3062 17.8564V24.2166C20.3062 24.476 20.2302 24.646 20.0783 24.7265C19.9264 24.807 19.7029 24.8472 19.408 24.8472C19.122 24.8472 18.903 24.807 18.7511 24.7265C18.5992 24.646 18.5232 24.476 18.5232 24.2166V16.0047C18.5232 15.3964 18.8494 15.0923 19.5018 15.0923C19.9398 15.0923 20.2928 15.2488 20.5609 15.5619L26.7278 23.009L32.9482 15.5619Z"
        fill="black"
      />
      <path
        d="M17.1457 22.016C17.1457 22.5885 17.0295 23.0581 16.7972 23.4249C16.5737 23.7827 16.2788 24.0645 15.9124 24.2702C15.5459 24.467 15.1214 24.6057 14.6388 24.6862C14.1651 24.7578 13.6825 24.7935 13.1909 24.7935H5.61644C5.20532 24.7935 4.99976 24.5833 4.99976 24.1629V15.6827C4.99976 15.2712 5.20532 15.0654 5.61644 15.0654H13.0837C13.6646 15.0654 14.1964 15.1057 14.679 15.1862C15.1616 15.2667 15.5772 15.4143 15.9258 15.629C16.2743 15.8347 16.5425 16.1165 16.7301 16.4743C16.9268 16.8321 17.0251 17.2928 17.0251 17.8564C17.0251 18.3305 16.9357 18.742 16.757 19.0909C16.5871 19.4397 16.2654 19.7305 15.7917 19.963C16.2028 20.1151 16.529 20.3477 16.7704 20.6608C17.0206 20.9649 17.1457 21.4167 17.1457 22.016ZM6.82299 19.1848H13.2043C13.6065 19.1848 13.9417 19.1669 14.2098 19.1311C14.4779 19.0953 14.6924 19.0327 14.8533 18.9433C15.0231 18.8449 15.1393 18.7107 15.2018 18.5407C15.2733 18.3618 15.3091 18.1292 15.3091 17.843C15.3091 17.6104 15.2823 17.4091 15.2287 17.2392C15.175 17.0692 15.0722 16.9305 14.9203 16.8232C14.7773 16.7159 14.5718 16.6398 14.3036 16.5951C14.0355 16.5414 13.6914 16.5146 13.2714 16.5146H6.82299V19.1848ZM15.3225 22.0697C15.3225 21.8103 15.2957 21.5911 15.2421 21.4122C15.1884 21.2333 15.0812 21.0857 14.9203 20.9694C14.7594 20.8531 14.5315 20.7726 14.2366 20.7279C13.9506 20.6742 13.5752 20.6474 13.1105 20.6474H6.78277V23.3444H13.2982C13.7361 23.3444 14.0891 23.322 14.3573 23.2773C14.6254 23.2236 14.8309 23.1476 14.9739 23.0492C15.1169 22.9418 15.2108 22.8077 15.2555 22.6467C15.3002 22.4856 15.3225 22.2933 15.3225 22.0697Z"
        fill="black"
      />
    </svg>
  );
};
