export const StackIcon: React.FC<{ size?: number }> = ({ size = 40 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24.5" cy="24" r="24" fill="white" />
      <mask
        id="mask0_134_9446"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="4"
        width="39"
        height="40"
      >
        <rect
          x="5.30005"
          y="4.7998"
          width="38.4"
          height="38.4"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_134_9446)">
        <path
          d="M24.5002 22.4C28.5002 22.4 31.9002 21.7733 34.7002 20.52C37.5002 19.2666 38.9002 17.76 38.9002 16C38.9002 14.24 37.5002 12.7333 34.7002 11.48C31.9002 10.2266 28.5002 9.59998 24.5002 9.59998C20.5002 9.59998 17.1002 10.2266 14.3002 11.48C11.5002 12.7333 10.1002 14.24 10.1002 16C10.1002 17.76 11.5002 19.2666 14.3002 20.52C17.1002 21.7733 20.5002 22.4 24.5002 22.4ZM24.5002 26.4C25.5936 26.4 26.9602 26.2866 28.6002 26.06C30.2402 25.8333 31.8202 25.4666 33.3402 24.96C34.8602 24.4533 36.1669 23.7933 37.2602 22.98C38.3536 22.1666 38.9002 21.1733 38.9002 20V24C38.9002 25.1733 38.3536 26.1666 37.2602 26.98C36.1669 27.7933 34.8602 28.4533 33.3402 28.96C31.8202 29.4666 30.2402 29.8333 28.6002 30.06C26.9602 30.2866 25.5936 30.4 24.5002 30.4C23.4069 30.4 22.0402 30.2866 20.4002 30.06C18.7602 29.8333 17.1802 29.4666 15.6602 28.96C14.1402 28.4533 12.8336 27.7933 11.7402 26.98C10.6469 26.1666 10.1002 25.1733 10.1002 24V20C10.1002 21.1733 10.6469 22.1666 11.7402 22.98C12.8336 23.7933 14.1402 24.4533 15.6602 24.96C17.1802 25.4666 18.7602 25.8333 20.4002 26.06C22.0402 26.2866 23.4069 26.4 24.5002 26.4ZM24.5002 34.4C25.5936 34.4 26.9602 34.2866 28.6002 34.06C30.2402 33.8333 31.8202 33.4666 33.3402 32.96C34.8602 32.4533 36.1669 31.7933 37.2602 30.98C38.3536 30.1666 38.9002 29.1733 38.9002 28V32C38.9002 33.1733 38.3536 34.1666 37.2602 34.98C36.1669 35.7933 34.8602 36.4533 33.3402 36.96C31.8202 37.4666 30.2402 37.8333 28.6002 38.06C26.9602 38.2866 25.5936 38.4 24.5002 38.4C23.4069 38.4 22.0402 38.2866 20.4002 38.06C18.7602 37.8333 17.1802 37.4666 15.6602 36.96C14.1402 36.4533 12.8336 35.7933 11.7402 34.98C10.6469 34.1666 10.1002 33.1733 10.1002 32V28C10.1002 29.1733 10.6469 30.1666 11.7402 30.98C12.8336 31.7933 14.1402 32.4533 15.6602 32.96C17.1802 33.4666 18.7602 33.8333 20.4002 34.06C22.0402 34.2866 23.4069 34.4 24.5002 34.4Z"
          fill="black"
        />
      </g>
    </svg>
  );
};
