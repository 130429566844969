export const BehindIcon: React.FC<{ size?: number }> = ({ size = 40 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="white" />
      <path
        d="M22.725 9.77342C23.2719 10.1015 23.6 10.6484 23.6 11.25V15.1875H28.4672C28.6312 14.4765 29.3422 13.875 30.1625 13.875H34.975C36.3969 13.875 37.6 15.0781 37.6 16.5C37.6 17.9765 36.3969 19.125 34.975 19.125H30.1625C29.3422 19.125 28.6312 18.5781 28.4672 17.8125H23.6V21.75C23.6 22.4062 23.2719 22.9531 22.725 23.2812C22.2328 23.6094 21.5765 23.6094 21.0297 23.3359L10.5297 18.0859C9.9281 17.8125 9.59998 17.2109 9.59998 16.5C9.59998 15.8437 9.9281 15.2422 10.5297 14.9687L21.0297 9.71873C21.5765 9.44529 22.2328 9.44529 22.725 9.77342ZM27.975 21.75H34.975C36.3969 21.75 37.6 22.9531 37.6 24.375V30.0625C37.6 30.1719 37.5453 30.2265 37.5453 30.2812C37.5453 30.3906 37.6 30.4453 37.6 30.5V34.875C37.6 36.3515 36.3969 37.5 34.975 37.5H12.225C10.7484 37.5 9.59998 36.3515 9.59998 34.875V30.5C9.59998 29.0781 10.7484 27.875 12.225 27.875H25.35V24.375C25.35 22.9531 26.4984 21.75 27.975 21.75ZM23.6 30.5V34.875H34.975V30.5H23.6ZM20.975 30.5H12.225V34.875H20.975V30.5ZM34.975 24.375H27.975V27.875H34.975V24.375Z"
        fill="black"
      />
    </svg>
  );
};
